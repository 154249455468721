import React from "react";
import { useState, useEffect } from "react";
import { ChevronLeft, Clipboard } from "lucide-react";
import Lottie from "lottie-react";
import AnimationPage from "../../public/animations/animationPage.json";

const VerifyPage = ({
  code,
  setCode,
  phone,
  setCurrentPage,
  invalidCode,
  setInvalidCode,
  sendNewCode,
  renderBack,
  isLoading,
}: {
  code: string[];
  setCode: (code: string[]) => void;
  phone: string;
  setCurrentPage: (page: string) => void;
  invalidCode: boolean;
  setInvalidCode: (invalid: boolean) => void;
  sendNewCode: () => void;
  renderBack?: boolean;
  isLoading?: boolean;
}) => {
  const [resendTimeout, setResendTimeout] = useState(30);
  const [error, setError] = useState(false);

  // Start a countdown when the component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      setResendTimeout((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    //focus the first input when the component mounts
    const input = document.querySelector("input");
    if (input) {
      input.focus();
    }

    return () => clearInterval(interval);
  }, []);

  const handleChange = (element: HTMLInputElement, index: number) => {
    const newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);
    setInvalidCode(false);

    // Automatically move to the next input if a digit was entered
    if (element.value && element.nextSibling) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      // Move focus to the previous input box when backspace is pressed
      // and the current box is already empty (user is deleting backwards)
      const previousSibling = (e.target as HTMLInputElement).previousElementSibling as HTMLInputElement;
      if (previousSibling) {
        previousSibling.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text").slice(0, 4); // Take only the first 4 characters
    const numbers = paste.replace(/\D/g, ""); // Remove all non-digits

    if (numbers.length === 4) {
      // If there are 4 digits, update the code array and focus the last input
      const newCode = numbers.split("");
      setCode(newCode);
      // After pasting, focus the last input element
      const lastInputIndex = newCode.length - 1;
      const inputs = Array.from(document.querySelectorAll("input"));
      if (inputs[lastInputIndex]) {
        inputs[lastInputIndex].focus();
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-start justify-center">
        <div className="bg-bq-200 mx-4 flex h-72 w-96 items-center justify-center rounded-xl px-8 py-9">
          <Lottie animationData={AnimationPage} style={{ width: 100, height: 100 }} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-start justify-center">
      <div className="bg-bq-200 mx-4 w-96 justify-between rounded-xl px-8 py-9">
        {/* back arrow */}
        {renderBack !== false && (
          <div className="mb-6 flex items-center">
            <button
              onClick={() => {
                setCurrentPage("phone");
                setCode(new Array(4).fill(""));
              }}
              className="bg-bq-500 hover:bg-bq-600 focus:shadow-outline items-center justify-center rounded-full p-1 focus:outline-none"
            >
              <ChevronLeft size={20} color="#f8f7f8" />
            </button>
          </div>
        )}
        {/* content */}
        <div className="flex flex-col items-start">
          <h1 className="text-bq-950 mb-3 text-2xl font-bold">Enter Code</h1>
          <p className="text-bq-900">We sent a code to {phone}. Please enter it below.</p>
          {invalidCode && <p className="mt-1 text-xs text-red-500">Invalid code.</p>}
        </div>
        <div className="my-5 flex w-72 justify-between sm:w-full">
          {code.map((digit, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)} // Handle key down events
              onPaste={handlePaste}
              className="border-bq-300 bg-bq-50 mx-1 w-14 border-b-2 px-2 py-3 text-center text-xl"
              disabled={code.join("").length === 4}
            />
          ))}
        </div>
        <div className="mt-8 flex flex-row items-center justify-between">
          <button
            onClick={() => {
              //copy code from clipboard to input
              void navigator.clipboard.readText().then((clipText) => {
                //if the clipboard has a code, paste it into the input
                if (clipText.length === 4) {
                  setCode(clipText.split(""));
                }
              });
            }}
            className="bg-bq-500 hover:bg-bq-600 text-bq-100 flex flex-row items-center justify-center rounded px-2 py-1.5 shadow"
          >
            <Clipboard size={16} />
            <p className="ml-2 text-sm">Paste Code</p>
          </button>
          {resendTimeout > 0 ? (
            <p className="text-bq-500 text-base">Resend Code in {resendTimeout} sec</p>
          ) : (
            <button
              onClick={() => {
                setResendTimeout(30);
                sendNewCode();
              }}
              className="text-bq-800 px-2"
            >
              <p className="ml-2 text-base">Resend Code</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
