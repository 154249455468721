import React from "react";
import Lottie from "lottie-react";
import AnimationPage from "../../../public/animations/animationPage.json";

const CustomLoader = ({ width = 100, height = 100 }: { width?: number; height?: number }) => {
  return (
    <div className="flex h-10 w-full items-center justify-center sm:mt-6">
      <Lottie animationData={AnimationPage} style={{ width: width, height: height }} className="-mt-24" />
    </div>
  );
};

export default CustomLoader;
