// pages/joinNLogin.tsx
import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";
import CompleteProfile from "~/components/joinNLogin/CompleteProfile";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import VerifyPage from "~/login/VerifyPage";
import PhoneInputScreen from "~/login/PhoneInput";
import { useToast } from "~/components/ui/use-toast";
import { useRouter } from "next/router";

type Step = "phone" | "codeVerification" | "infoInput" | null;

const LoginPage = () => {
  const [currentPage, setCurrentPage] = useState<Step>("phone");
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [code, setCode] = useState(new Array(4).fill(""));
  const [correctCode, setCorrectCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [dietRestrictions, setDietRestrictions] = useState<string[]>([]);
  const { data: diets } = api.profile.getAllDiets.useQuery();
  const sendVerificationCode = api.auth.sendVerificationCode.useMutation();
  const { data: profileData, refetch: refetchProfile } = api.profile.getProfile.useQuery();
  const getOrCreateUserIdOnce = api.auth.getOrCreateUserIdOnce.useMutation();
  const updateProfileBasics = api.profile.updateProfileBasics.useMutation();
  const updateMultiChoiceAttributes = api.profile.updateMultiChoiceAttributes.useMutation();
  const { toast } = useToast();
  const router = useRouter();

  const sampleFields = [
    {
      name: "firstName",
      description: "First Name",
      type: "text",
      required: true,
    },
    {
      name: "lastName",
      description: "Last Name",
      type: "text",
      required: true,
    },
    {
      name: "email",
      description: "Email",
      type: "email",
      required: true,
    },
  ];

  useEffect(() => {
    if (typeof window !== "undefined" && localStorage.getItem("token") === null) {
      setCurrentPage("phone");
    }
  }, [router]);

  const phoneUtil = PhoneNumberUtil.getInstance();

  interface ValidationResult {
    isValid: boolean;
    formattedPhone?: string;
  }

  const validateAndFormatPhone = (phone: string): ValidationResult => {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone);
      if (phoneUtil.isValidNumber(number)) {
        let formattedPhone = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
        formattedPhone = formattedPhone.replace(/-/g, " "); // Replaces hyphens with spaces
        return {
          isValid: true,
          formattedPhone: formattedPhone,
        };
      }
    } catch (error) {
      console.error("Error validating phone number:", error);
    }
    return { isValid: false };
  };

  const handlePhoneSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentPage === "phone") {
      const result = validateAndFormatPhone(phone);
      setIsValidNumber(result.isValid);

      if (result.isValid && result.formattedPhone) {
        setIsLoading(true);
        setFormattedPhone(result.formattedPhone);
        sendVerificationCode
          .mutateAsync({ phone: result.formattedPhone })
          .then((code) => {
            setCorrectCode(code);
            setCurrentPage("codeVerification");
            setIsLoading(false);
          })
          .catch((e) => console.error(e));
      }
    }
  };

  const sendNewCode = () => {
    sendVerificationCode.mutateAsync({ phone: formattedPhone }).then((code) => {
      setCorrectCode(code);
      setCode(new Array(4).fill(""));
    });
  };

  useEffect(() => {
    // Focus the first input when the component mounts
    const input = document.querySelector("input");
    if (input) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    //run handleVerify when code changes
    handleVerify();
  }, [code]);

  const handleVerify = () => {
    try {
      //check to see if all the digits are filled
      if (code.join("").length === 4) {
        //check to see if the code is correct
        if (code.join("") === correctCode) {
          setIsLoading(true);
          handleCorrectCode();
          setCode(new Array(4).fill(""));
        } else {
          setInvalidCode(true);
          setCode(new Array(4).fill(""));
          setTimeout(() => {
            setInvalidCode(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCorrectCode = async () => {
    //grabs the phone number and attempts to log/create the user
    await getOrCreateUserIdOnce.mutateAsync({ phone: formattedPhone }).then(async (data) => {
      localStorage.setItem("token", data.id);
      // check to see if the user has all the fields
      const hasAllFields = sampleFields.every((field) => data[field.name as keyof typeof data]);
      //if they do then take them back to the previous page
      if (hasAllFields) {
        //check if router has back
        if (router.back) {
          router.back();
        } else {
          await router.push("/profile");
        }
      }
      //if they don't then take them to the infoInput page
      else {
        refetchProfile();
        setIsLoading(false);
        setCurrentPage("infoInput");
      }
    });
  };

  function handleDietChange(diet: string) {
    if (diet === "None") {
      setDietRestrictions([diet]);
      return;
    }

    // Deselect everything else if 'None' is selected
    if (dietRestrictions.includes("None")) {
      setDietRestrictions([diet]);
      return;
    }

    // Deselect 'None' if any pill is pressed
    if (dietRestrictions.includes(diet)) {
      const updatedDiets = dietRestrictions.filter((d) => d !== diet);
      setDietRestrictions(updatedDiets);
    } else {
      setDietRestrictions([...dietRestrictions, diet]);
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (dietRestrictions.length === 0) {
      toast({
        variant: "destructive",
        title: "Dietary restrictions required",
        description: "Please select none if you have no dietary restrictions",
      });
      return;
    }
    setIsLoading(true);
    const form = e.currentTarget;
    const formData = new FormData(form);

    // Initialize an empty object to store formData entries
    const formDataObject: { [key: string]: FormDataEntryValue } = {};

    // Iterate over formData entries to populate the formDataObject
    for (const [name, value] of formData.entries()) {
      formDataObject[name] = value;
    }

    updateProfileBasics
      .mutateAsync(formDataObject)
      .then(() => {
        updateMultiChoiceAttributes
          .mutateAsync({ userId: profileData?.id, diets: dietRestrictions })
          .catch((e) => {
            console.error(e);
          })
          .then(() => {
            setIsLoading(false);
            if (router.back) {
              router.back();
            } else {
              router.push("/profile");
            }
          })
          .catch((e) => {
            console.log("Error:", e);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.error("Error:", e);
        setIsLoading(false);

        if (e.message && e.message.includes("Invalid email")) {
          toast({
            variant: "destructive",
            title: "Invalid email",
            description: "Please enter a valid email",
          });
        } else if (e.message && e.message.includes("Unique constraint failed on the constraint: `User_email_key`")) {
          // Show a toast or handle the error as needed
          toast({
            variant: "destructive",
            title: "Email already exists",
            description: "Please use a different email",
          });
        }
      });
  };

  const handleStepRender = () => {
    switch (currentPage) {
      case "phone":
        return (
          <PhoneInputScreen
            phone={phone}
            setPhone={setPhone}
            isValidNumber={isValidNumber}
            handleSubmit={handlePhoneSubmit}
            isLoading={isLoading}
          />
        );
      case "codeVerification":
        return (
          <VerifyPage
            code={code}
            setCode={setCode}
            phone={formattedPhone}
            setCurrentPage={setCurrentPage}
            invalidCode={invalidCode}
            setInvalidCode={setInvalidCode}
            sendNewCode={sendNewCode}
            renderBack={false}
            isLoading={isLoading}
          />
        );
      case "infoInput":
        return (
          <CompleteProfile
            fields={sampleFields}
            handleSubmit={handleFormSubmit}
            isLoading={isLoading}
            handleDietChange={handleDietChange}
            diets={diets}
            dietRestrictions={dietRestrictions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-full w-full">
      <div className="mt-16 w-screen sm:mt-24">
        {/* header */}
        {handleStepRender()}
      </div>
    </div>
  );
};

export default LoginPage;
