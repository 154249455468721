import React, { useState } from "react";
import { ClickablePills, enumValueToString, titleCase } from "../../pages/profile/index";
import { api } from "~/utils/api";
import { useToast } from "~/components/ui/use-toast";
import CustomLoader from "../ui/customLoader";

type Field = {
  name: string;
  description: string;
  required?: boolean;
  type?: string;
  value?: string;
};

interface CompleteProfileProps {
  fields: Field[];
  isLoading: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleDietChange: (diet: string) => void;
  diets: string[];
  dietRestrictions: string[];
}

const CompleteProfile: React.FC<CompleteProfileProps> = ({
  fields,
  isLoading,
  handleSubmit,
  handleDietChange,
  diets,
  dietRestrictions,
}) => {
  const DietRestrictionsRender = () => (
    <div className="mt-1 flex flex-row flex-wrap justify-center">
      <ClickablePills values={diets || []} onChange={handleDietChange} userValues={dietRestrictions} />
    </div>
  );

  return (
    <div className="flex h-full w-full items-start justify-center md:-mt-2">
      <div className="bg-bq-200 mx-4 w-96 rounded-xl p-8">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="space-y-4">
            {fields.map((field, index) => (
              <div key={index} className="flex flex-col">
                <label htmlFor={field.name} className="mb-2 capitalize">
                  {field.description}
                  {field.required && <span className="text-red-500">*</span>}
                </label>
                <input
                  type="text"
                  id={field.name}
                  name={field.name}
                  placeholder={`Enter your ${field.description}`}
                  value={field.value}
                  required={field.required}
                  className="focus:border-bq-400 rounded-md border border-gray-300 p-2 focus:outline-none"
                />
              </div>
            ))}
          </div>
          <div className=" mt-4">
            <label htmlFor={"bio"} className="text-bq-950 mb-2 text-base">
              Dietary Restrictions
              <span className="text-red-500">*</span>
            </label>
            {diets && <DietRestrictionsRender />}
          </div>
          <div className="mb-2 mt-6 flex items-center">
            {isLoading ? (
              <CustomLoader width={50} height={50} />
            ) : (
              <button
                type="submit"
                className="bg-bq-600 hover:bg-bq-700 focus:shadow-outline w-full rounded-full px-4 py-2 font-bold text-white focus:outline-none"
                disabled={isLoading}
              >
                Complete Profile
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompleteProfile;
