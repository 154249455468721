import React from "react";
import { GiForkKnifeSpoon } from "react-icons/gi";
import Lottie from "lottie-react";
import AnimationPage from "../../public/animations/animationPage.json";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const PhoneInputScreen = ({
  phone,
  setPhone,
  isValidNumber,
  handleSubmit,
  isLoading,
}: {
  phone: string;
  setPhone: (phone: string) => void;
  isValidNumber: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}) => {
  return (
    <div className="flex h-full w-full items-start justify-center">
      <div className="bg-bq-200 mx-4 w-96 rounded-xl p-8">
        <div className="flex flex-col items-start">
          <div className="bg-bq-700 rounded-full p-3">
            {/* Replace icon placeholder with your actual logo image */}
            <GiForkKnifeSpoon size={32} color="#f8f7f8" />
          </div>
          <h1 className="text-bq-950 mt-4 text-2xl font-bold">Welcome to Banquette</h1>
          <p className="text-bq-900 mb-4">Please sign in or sign up below.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-4">
            <label htmlFor="phone" className="text-bq-950 mb-2 block text-sm font-bold">
              Phone Number
            </label>
            <PhoneInput
              defaultCountry="us"
              value={phone}
              onChange={setPhone}
              placeholder="+1 (123) 456-7890"
              style={{
                width: "100%",
                padding: "0.5rem",
                borderRadius: "0.375rem",
                border: "1px solid #e5e7eb",
                backgroundColor: "white",
              }}
              inputStyle={{
                // backgroundColor: "gold",
                width: "100%",
                height: "2.5rem",
                border: "none",
                borderTopRightRadius: "0.375rem",
                borderBottomRightRadius: "0.375rem",
                padding: "0.5rem",
                fontSize: "1rem",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  width: "3rem",
                  height: "2.5rem",
                  borderTopLeftRadius: "0.375rem",
                  borderBottomLeftRadius: "0.375rem",
                  border: "none",
                  borderRight: "1px solid #e5e7eb",
                },
              }}
            />
            {!isValidNumber && <p className="mt-1 text-xs text-red-500">Invalid phone number.</p>}
          </div>
          <div className="mb-2 mt-6 flex items-center">
            {isLoading ? (
              <div className="flex h-10 w-full items-center justify-center">
                <Lottie animationData={AnimationPage} style={{ width: 50, height: 50 }} />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-bq-600 hover:bg-bq-700 focus:shadow-outline w-full rounded-full px-4 py-2 font-bold text-white focus:outline-none"
                disabled={isLoading}
              >
                Continue
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PhoneInputScreen;
